<script>
import request from '@/found/utils/request';
import Form, { formCreate } from '@/found/components/form';
import { string } from 'mathjs';
import TableSelect from '../components/tableSelect.vue';

formCreate.component('TableSelect', TableSelect);
export default {
  extends: Form,
  props: {
    parentCode: String,
  },
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      formFunctionCode: 'quota_management_adj',
      formParentCode: 'CRM20220328000000002',
    };
  },

  methods: {
    // 设置rule
    setRule(v) {
      const item = v;
      if (item.field === 'type') {
        item.options = [
          {
            label: '配额调增',
            value: '2',
          },
          {
            label: '配额调减',
            value: '3',
          },
        ];
      }
      if (item.field === 'companyName') {
        const paramsObj = {
          functionCode: 'select_company',
          data: [],
          selectionList: [],
          idKey: 'orgCode',
          noReset: true,
          paramData: { orgType: 'area' },
        };
        item.props = {
          ...item.props,
          paramsObj,
          placeholder: '分公司',
        };
      }
      if (item.field === 'productName') {
        const paramsObj = {
          functionCode: 'select_product',
          data: [],
          selectionList: [],
          idKey: 'productCode',
          noReset: true,
          paramData: { ext21: '1' },
        };
        item.props = {
          ...item.props,
          paramsObj,
          placeholder: '产品',
        };
      }
      if (item.field === 'seriesName') {
        const paramsObj = {
          functionCode: 'select_series',
          data: [],
          selectionList: [],
          idKey: 'seriesCode',
          noReset: true,
        };
        item.props = {
          ...item.props,
          paramsObj,
          placeholder: '系列',
        };
      }
      return item;
    },
    // 表单渲染完成后调用
    async formComplete() {
      const company = this.getRule('companyName');
      company.on.change = (val) => {
        this.setValue({
          companyCode: val.orgCode,
          companyName: val.orgName,
        });
      };
      const product = this.getRule('productName');
      product.on.change = (val) => {
        this.setValue({
          productCode: val.productCode,
          productName: val.productName,
          baseUnit: val.ext3,
        });
      };
      const series = this.getRule('seriesName');
      series.on.change = (val) => {
        this.setValue({
          seriesCode: val.productLevelCode,
          seriesName: val.productLevelName,
        });
      };
      if (this.formConfig.row.productOrSeries === 'product') {
        this.fApi.hidden(true, ['seriesName', 'totalMoney', 'usedMoney', 'balanceMoney', 'money']);
        this.fApi.hidden(false, ['productName', 'totalAmount', 'usedAmount', 'balanceAmount', 'amount']);
      } else if (this.formConfig.row.productOrSeries === 'series') {
        this.fApi.hidden(true, ['productName', 'totalAmount', 'usedAmount', 'balanceAmount', 'amount']);
        this.fApi.hidden(false, ['seriesName', 'totalMoney', 'usedMoney', 'balanceMoney', 'money']);
      }
      this.disabled(true, ['quotaCode', 'year', 'quarter', 'companyName', 'productName', 'totalAmount', 'usedAmount', 'balanceAmount', 'productOrSeries', 'seriesName', 'totalMoney', 'usedMoney', 'balanceMoney']);
      if (this.formConfig.row.urlParam) {
        const url = '/dms/dms/companyQuotaDetail/query';
        const params = {
          id: this.formConfig.row.id,
        };
        const res = await request.get(url, params);
        this.setValue({ ...res.result, quotaCode: res.result.companyQuotaCode });
      } else {
        this.setValue({ ...this.formConfig.row });
      }
    },
    // 表单提交
    submit() {
      const formData = this.getFormData();
      formData.ratio *= 1;
      if ((formData.type === '3' && formData.balanceAmount < formData.amount) || (formData.type === '3' && formData.balanceMoney < formData.money)) {
        return this.$message.error('配额调减不能大于剩余数量！');
      }
      if (formData.amount) {
        const isRato = formData.amount % formData.ratio;
        if (isRato !== 0 && formData.ratio !== 0) {
          this.$message.error('配额总量必须为产品包装规格整数倍');
          return;
        }
      }
      if (formData) {
        const url = '/dms/dms/companyQuota/change';
        const params = {
          amount: formData.amount,
          quotaCode: formData.quotaCode,
          type: formData.type,
          ratio: formData.ratio,
          money: formData.money,
          productOrSeries: formData.productOrSeries,
          remarks: formData.remarks,
        };
        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
</script>
